<template>
	<div style="margin-top: 20px;">
		<van-loading vertical color="#1989fa">
		  <template #icon>
		    <van-icon name="star-o" size="30" />
		  </template>
		  加载中...
		</van-loading>
	</div>
</template>

<script>
	import request from "@/api/index.js";
	import { appId } from '@/settings.js'
	// 判断是否为微信环境
	const isWechat = () => {
		return String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) === "micromessenger";
	}
	export default {
		name: '',
		components: {

		},
		props: {},
		data() {
			return {
				code: '',
				openId: '',
				appId: appId
			}
		},
		computed: {
		},
		watch: {
		},
		created() {
			this.getWxCode();
			if(this.code != ''){
				this.getToken();
			}
		},
		mounted() {

		},
		methods: {
			getToken(){
			  request.get(process.env.VUE_APP_GZ_PHOENIX_URL + '/wechat/publicAccount/checkWechatOpenIdBindMobile',{ params: {code: this.code} }).then(res=>{
				if(res.code == 200){
          this.$router.push({path: '/gzBindPhoneSuccess', query: {openId: res.data.publicAccountOpenId, phone: res.data.phone}})
				}else if(res.code == 500){
					this.$router.push({path: '/bindWechatOpenIdAndPhone', query: {openId: res.data}})
				}else if(res.code == 501){
					this.$toast.fail(res.msg)
				}
				}).catch(err=>{
					this.$toast.fail(err.message)
				})
			},
			getWxCode() {
				if (isWechat) {
					let code = this.getUrlCode().code; //是否存在code
					let local = window.location.href;
					if (code == null || code === "") {
					  //不存在就打开上面的地址进行授权
					  window.location.href =
						"https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
                this.appId +
						"&redirect_uri=" +
						encodeURIComponent(local) +
						"&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
					} else {
						this.code = code;
					}
				}
			},
			getUrlCode() {
				// 截取url中的code方法
				var url = location.search;
				var theRequest = new Object();
				if (url.indexOf("?") != -1) {
					var str = url.substr(1);
					var strs = str.split("&");
					for (var i = 0; i < strs.length; i++) {
						theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
					}
				}
				return theRequest;
			},
		},
	}
</script>

<style>
</style>
